import { useParams } from "react-router-dom";
import ExplanationScreen01 from './Step1_Screens/ExplanationScreen01';
import ExplanationScreen02 from './Step1_Screens/ExplanationScreen02';
import ExplanationScreen03 from './Step1_Screens/ExplanationScreen03';
import VideoScreen from './Step1_Screens/VideoScreen'
import IntroductionScreen01 from './Step1_Screens/IntroductionScreen01';
import IntroductionScreen02 from './Step1_Screens/IntroductionScreen02';
import IdentifyScreen01 from './Step2_Screens/IdentifyScreen01';
import IdentifyScreen02 from './Step2_Screens/IdentifyScreen02';
import NegativeCoreScreen03 from './Step2_Screens/NegativeCoreScreen03';
import IdentifyScreen03 from './Step2_Screens/IdentifyScreen03';
import NegativeCoreScreen01 from './Step2_Screens/NegativeCoreScreen01';
import NegativeCoreScreen02 from './Step2_Screens/NegativeCoreScreen02';
import NegativeCoreScreen04 from './Step2_Screens/NegativeCoreScreen04';
import EmotionScreen01 from './Step2_Screens/EmotionScreen01';
import SUDScreen01 from './Step2_Screens/SUDScreen01';
import TensionScreen01 from './Step2_Screens/TensionScreen01';
import TensionScreen02 from './Step2_Screens/TensionScreen02';
import PositiveCloseScreen01 from './Step6_Screens/PositiveCloseScreen01';
import PositiveCloseScreen02 from './Step6_Screens/PositiveCloseScreen02';
import PositiveCloseScreen03 from './Step6_Screens/PositiveCloseScreen03';
import PositiveCloseScreen04 from './Step6_Screens/PositiveCloseScreen04';
import AudioButton from "../../components/AudioButton";
import { useTranslation } from "react-i18next";
import React from "react";

export default function SessionStepWrapper(props: any) {
  const { activeStep } = useParams();
  const { t } = useTranslation();
  let link = "https://storage.googleapis.com/moovd-apps/moovd-emdr-flash/README_INSTRUCTIONS/";
  link += localStorage.getItem("i18nextLng")?.includes("en") ? "ENG/" : "NL/";
  const voice = "j.vanzon/";

  const renderStep = () => {
    switch (activeStep) {

      case "100": return <ExplanationScreen01 />;
      case "101": return <ExplanationScreen02 />;
      case "102": return <ExplanationScreen03 />;
      case "103": return <VideoScreen />;
      case "2": return <IntroductionScreen01 />;
      case "3": return <IntroductionScreen02 />;
      case "4": return <IdentifyScreen01 />;
      case "5": return <IdentifyScreen02 />;
      case "6": return <IdentifyScreen03 />;
      case "7": return <NegativeCoreScreen01 />;
      case "8": return <NegativeCoreScreen02 />;
      case "9": return <NegativeCoreScreen03 />;
      case "10": return <NegativeCoreScreen04 />;
      case "11": return <EmotionScreen01 />;
      case "13": return <SUDScreen01 />;
      case "14": return <TensionScreen01 />;
      case "15": return <TensionScreen02 />;
      case "40": return <PositiveCloseScreen01 />;
      case "41": return <PositiveCloseScreen02 />;
      case "42": return <PositiveCloseScreen03 />;
      case "43": return <PositiveCloseScreen04 />;

      default: return <h1>No step match</h1>
    }
  }
    return (
      <div className="relative px-6 pb-6">
        <div className="p-6 bg-white shadow-xl min-h-screen-card flex flex-col justify-between">
          <div className="flex-none mb-6">
            <AudioButton src={link + voice + t('screens:' + activeStep + '.audio')} />
          </div>
          {renderStep()}
        </div>
    </div>
    );
};
