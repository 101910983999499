import { storeAnswer } from '../../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';

interface OwnProps {
}

export const Screen:React.FC<OwnProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function answer() {
    await storeAnswer('100', t('screens:100.uuid'));
    navigate('/session/101');
  }

  const actions = (<>
    <Button label={t('continue')} variant="primary" onClick={() => answer()} />
  </>);

  return (
    <>
    <SessionScreen
      screen="100"
      title={`${t('screens:100.step')} ${t('screens:100.title')}`}
      chapter={1}
      progress={0}
      audio={t('screens:100.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:100.text')} />
    </SessionScreen>
    </>
  );
};

export default Screen;