import { storeAnswer } from '../../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';

export default function Screen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function answer() {
    await storeAnswer('102', t('screens:102.uuid'));
    navigate('/session/103');
  }

  async function goBack() {
    navigate(-1);
  }

  const actions = (<>
    <Button label={t('go_back')} variant="secondary" onClick={() => goBack()} />
    <Button label={t('continue')} variant="primary" onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="102"
      title={`${t('screens:102.step')} ${t('screens:102.title')}`}
      chapter={1}
      progress={33}
      audio={t('screens:102.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:102.text')} />
    </SessionScreen>
  );
};
