import { storeAnswer } from '../../../services/manager';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';
import OptionField from '../../../components/OptionField';

export default function Screen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState('');

  async function answer() {
    await storeAnswer('42', t('screens:42.uuid'), val);
    if (val === 'yes') navigate('/session/41', { replace: true });
    if (val === 'no') navigate('/session/43', { replace: true });
  }

  const actions = (<>
    <Button label={t('continue')} variant="primary" disabled={val === ''} onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="42"
      title={`${t('screens:42.step')} ${t('screens:42.title')}`}
      chapter={6}
      progress={50}
      audio={t('screens:42.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:42.text')} />
      <div className="flex flex-col gap-2">
        <OptionField label={t('yes')} option="yes" value={val} onChange={setVal} />
        <OptionField label={t('no')} option="no" value={val} onChange={setVal} />
      </div>
    </SessionScreen>
  );
};
