import { storeAnswer } from '../../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';
import TextareaField from '../../../components/TextareaField';
import useValidation from '../../../hooks/useValidation';

export type StoredValue = string;

export default function Screen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState('');
  const validation = useValidation(val, 'min:1|max_words:5');

  async function answer() {
    await storeAnswer('9', t('screens:9.uuid'), validation.value as StoredValue);
    navigate('/session/10', { replace: true });
  }

  const actions = (<>
    <Button label={t('continue')} variant="primary" disabled={validation.failed} onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="9"
      title={`${t('screens:9.step')} ${t('screens:9.title')}`}
      chapter={2}
      progress={55}
      audio={t('screens:9.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:9.text')} />
      <TextareaField value={val} onChange={setVal} maxLength={450} placeholder={t('Max 5 woorden')} />
    </SessionScreen>
  );
};
