import { storeAnswer } from '../../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';
import TextareaField from '../../../components/TextareaField';
import { useState } from 'react';
import useValidation from '../../../hooks/useValidation';

export type StoredValue = string;

export default function Screen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState('');
  const validation = useValidation(val, 'min:1');

  async function answer() {
    await storeAnswer('5', t('screens:5.uuid'), validation.value as StoredValue);
    navigate('/session/6', { replace: true });
  }

  async function goBack() {
    navigate(-1);
  }

  const actions = (<>
    <Button label={t('go_back')} variant="secondary" onClick={() => goBack()} />
    <Button label={t('continue')} variant="primary" disabled={validation.failed} onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="5"
      title={`${t('screens:5.step')} ${t('screens:5.title')}`}
      chapter={2}
      progress={11}
      audio={t('screens:5.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:5.text')} />
      <TextareaField value={val} onChange={setVal} maxLength={450} placeholder={t('write_your_answer_here')} />
    </SessionScreen>
  );
};
