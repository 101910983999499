type Props = {
  chapter: number;
  chapters?: Record<number, number>;
  progress: number;
};

const defaultChapters = {
  1: 0,
  2: 15,
  3: 45,
  4: 75,
  5: 85,
  6: 100,
};

export default function SessionProgress({
  chapter,
  chapters = defaultChapters,
  progress,
}: Props) {

  const chapterKeys = Object.keys(chapters).map(Number);
  const isLastChapter = chapterKeys[chapterKeys.length - 1] === chapter;

  const currentChapter = chapters[chapter];
  const nextChapter = chapters[chapter + 1] ? chapters[chapter + 1] : 100;
  const diffBetweenChapters = nextChapter - currentChapter;
  const chapterProgress = currentChapter + (diffBetweenChapters * (progress / 100));

  const renderChapters = Object.entries(chapters).map(([number, value], index) => (
    <div
      key={index}
      style={{ left: `${value}%` }}
      className="rounded-full bg-lightblue w-4.5 h-4.5 absolute text-white text-2xs text-center leading-4.5 font-semibold top-0 -translate-x-1/2">
      {number}
    </div>
  ));

  return (
    <div className="relative my-4 py-1">
      <div className="relative rounded-full bg-white h-2 overflow-hidden px-6">
        <div className="absolute top-0 bg-blue h-2 w-6 left-0"></div>
        <div className="absolute top-0 bg-blue h-2 w-6 right-0" style={{ visibility: isLastChapter && progress === 100 ? 'visible' : 'hidden' }}></div>
        <div className="rounded-full bg-blue h-2 transition-[width]" style={{ width: `${chapterProgress}%` }}></div>
      </div>
      <div className="absolute inset-x-0 top-0 h-4 mx-6">
        {renderChapters}
      </div>
    </div>
  );
};
