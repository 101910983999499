import { clearSession, finishSession, processQueue } from '../../../services/manager';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';

export default function Screen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    (async function() {
      await processQueue();
      await finishSession();
    })();
  });

  async function answer() {
    await clearSession();
    navigate('/', { replace: true });
  }

  async function goBack() {
    navigate(-1);
  }

  const actions = (<>
    <Button label={t('go_back')} variant="secondary" onClick={() => goBack()} />
    <Button label={t('exit')} variant="primary" onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="43"
      title={`${t('screens:43.step')} ${t('screens:43.title')}`}
      chapter={6}
      progress={75}
      audio={t('screens:43.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:43.text')} />
    </SessionScreen>
  );
};
