import { getSession } from './services/manager';
import { Routes, Route, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PageNotFoundScreen from './screens/PageNotFoundScreen';
import SessionLayout from './layouts/SessionLayout';
import StartSessionScreen from './screens/StartSessionScreen';
import useScrollToTop from './hooks/useScrollToTop';

// Session screens
import WelcomeScreen from './screens/session/WelcomeScreen';
import UserAgreementScreen from './screens/session/UserAgreementScreen';
import RotateInstructionScreen from './screens/session/RotateInstructionScreen';
import RotateBackInstructionScreen from './screens/session/RotateBackInstructionScreen';
import SessionStepWrapper from './screens/session/SessionStepWrapper';

export default function App() {
  useScrollToTop();

  const session = getSession();
  const [isGuarded, setGuarded] = useState<boolean>(session ? false : true);

  useEffect(() => {
    setGuarded(session ? false : true);
  }, [session]);

  return (
    <Routes>
      {!isGuarded && <>
        <Route path="/session/welcome" element={<WelcomeScreen />} />
        <Route path="/session/user_rights_agreement" element={<UserAgreementScreen />} />
        <Route path="/session/rotate-instruction" element={<RotateInstructionScreen />} />
        <Route path="/session/continue" element={<RotateBackInstructionScreen />} />
        <Route path="/session" element={<SessionLayout />}>

          <Route path=":activeStep" element={<SessionStepWrapper />} />

        </Route>
      </>}
      <Route path="/" element={<StartSessionScreen />} />
      <Route path="*" element={<PageNotFoundScreen />} />
    </Routes>
  );
};
