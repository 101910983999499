import { storeAnswer } from '../../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';
import OptionField from '../../../components/OptionField';
import { useState } from 'react';

export default function Screen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState('');

  async function answer() {
    await storeAnswer('6', t('screens:6.uuid'));
    if (val === 'yes') navigate('/session/4', { replace: true });
    if (val === 'no') navigate('/session/7', { replace: true });
  }

  const actions = (<>
    <Button label={t('continue')} variant="primary" disabled={val === ''} onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="6"
      title={`${t('screens:6.step')} ${t('screens:6.title')}`}
      chapter={2}
      progress={22}
      audio={t('screens:6.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:6.text')} />
      <div className="flex flex-col gap-2">
        <OptionField label={t('yes_there_is_a_worse_scary_image')} option="yes" value={val} onChange={setVal} />
        <OptionField label={t('no_this_is_the_worst_scary_image')} option="no" value={val} onChange={setVal} />
      </div>
    </SessionScreen>
  );
};
