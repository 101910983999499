import { storeAnswer } from '../../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';
import usePreviousNCValue from '../../../hooks/usePreviousNCValue';

export type StoredValue = string;

export default function Screen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let nc = usePreviousNCValue('10');

  async function answer(variable: String) {
    await storeAnswer('10', t('screens:10.uuid'), nc as StoredValue);
    if (variable === "Yes") navigate('/session/11', { replace: true });
    if (variable === "No") navigate('/session/7', { replace: true });
  }

  const actions = (<>
    <Button label={t('yes')} variant="primary" onClick={() => answer("Yes")} />
    <Button label={t('no')} variant="primary" onClick={() => answer("No")} />
  </>);

  return (
    <SessionScreen
      screen="10"
      title={`${t('screens:10.step')} ${t('screens:10.title')}`}
      chapter={2}
      progress={66}
      audio={t('screens:10.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:10.text', { nc: t(nc) })} />
    </SessionScreen>
  );
};
