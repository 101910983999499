import { storeAnswer } from '../../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';
import usePreviousNCValue from '../../../hooks/usePreviousNCValue';

export default function Screen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const nc = usePreviousNCValue('3');

  async function answer() {
    await storeAnswer('4', t('screens:4.uuid'));
    navigate('/session/5');
  }

  const actions = (<>
    <Button label={t('continue')} variant="primary" onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="4"
      title={`${t('screens:4.step')} ${t('screens:4.title')}`}
      chapter={2}
      progress={0}
      audio={t('screens:4.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:4.text', { nc: t(nc) })} />
    </SessionScreen>
  );
};
