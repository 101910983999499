import { storeAnswer } from '../../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import ScaleOptionField from '../../../components/ScaleOptionField';
import SessionScreen from '.././SessionScreen';
import usePreviousNCValue from '../../../hooks/usePreviousNCValue';

export default function Screen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const nc = usePreviousNCValue('13');
  const [val, setVal] = useState('');

  async function answer() {
    await storeAnswer('13', t('screens:13.uuid'), val);
    navigate('/session/14', { replace: true });
  }

  async function goBack() {
    navigate(-1);
  }

  const actions = (<>
    <Button label={t('go_back')} variant="secondary" onClick={() => goBack()} />
    <Button label={t('continue')} variant="primary" disabled={val === ''} onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="13"
      title={`${t('screens:13.step')} ${t('screens:13.title')}`}
      chapter={2}
      progress={88}
      audio={t('screens:13.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:13.text', { nc: t(nc) })} />

      <div className="max-w-16rem smt-10 flex flex-wrap justify-center gap-3">
        <ScaleOptionField label="0" option="0" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="1" option="1" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="2" option="2" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="3" option="3" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="4" option="4" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="5" option="5" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="6" option="6" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="7" option="7" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="8" option="8" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="9" option="9" value={val} variant="secondary" group="scale" onChange={setVal} />
        <ScaleOptionField label="10" option="10" value={val} variant="secondary" group="scale" onChange={setVal} />
      </div>
    </SessionScreen>
  );
};
