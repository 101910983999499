import { storeAnswer } from '../../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';
import usePreviousNCValue from '../../../hooks/usePreviousNCValue';

export default function Screen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const nc = usePreviousNCValue('3');

  async function answer() {
    await storeAnswer('3', t('screens:3.uuid'));
    navigate('/session/4', { replace: true });
  }

  async function goBack() {
    navigate(-1);
  }

  const actions = (<>
    <Button label={t('go_back')} variant="secondary" onClick={() => goBack()} />
    <Button label={t('continue')} variant="primary" onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="3"
      title={`${t('screens:3.step')} ${t('screens:3.title')}`}
      chapter={1}
      progress={84}
      audio={t('screens:3.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:3.text', { nc: t(nc) })} />
    </SessionScreen>
  );
};
