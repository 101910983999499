import { storeAnswer } from '../../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';
import { useEffect, useState } from 'react';
import TextField from '../../../components/TextField';
import useValidation from '../../../hooks/useValidation';
import usePreviousNCValue from '../../../hooks/usePreviousNCValue';

export type StoredValue = string;

export default function Screen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const oldData = usePreviousNCValue('2');
  const [val, setVal] = useState(oldData !== '(unknown)' ? oldData : '');
  const validation = useValidation(val, 'min:1');

  async function answer() {
    await storeAnswer('2', t('screens:2.uuid'), validation.value as StoredValue);
    navigate('/session/3');
  }

  async function goBack() {
    navigate(-1);
  }

  const actions = (<>
    <Button label={t('go_back')} variant="secondary" onClick={() => goBack()} />
    <Button label={t('continue')} variant="primary" disabled={validation.failed} onClick={() => answer()} />
  </>);

  useEffect(() => {
    if (oldData !== "(unknown)") {
      setVal(oldData);
    } else {
      setVal('')
    }
  }, [oldData])


  return (
    <SessionScreen
      screen="2"
      title={`${t('screens:2.step')} ${t('screens:2.title')}`}
      chapter={1}
      progress={67}
      audio={t('screens:2.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:2.text')} />
      <TextField value={val} onChange={setVal} maxLength={50} placeholder={t('write_your_answer_here')} />
    </SessionScreen>
  );
};
