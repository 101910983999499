import { storeAnswer } from '../../../services/manager';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';
import CheckField from '../../../components/CheckField';
import usePreviousNCValue from '../../../hooks/usePreviousNCValue';
import Dialog from '../../../components/Dialog';

export type StoredValue = {
  angry: 'yes' | 'no';
  scared: 'yes' | 'no';
  sad: 'yes' | 'no';
}

export default function Screen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const nc = usePreviousNCValue('11');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [checkedValues, setCheckedValue] = useState<StoredValue>({
    angry: 'no',
    scared: 'no',
    sad: 'no',
  });

  function handleCheck(key: string, state: string): void {
    setCheckedValue({
      ...checkedValues,
      [key as keyof StoredValue]: state,
    });
  }

  async function answer() {
    if (Object.values(checkedValues).filter(v => v === 'yes').length === 0) {
      setErrorMessage(t('select_at_least_1_emotion_that_most_suits_you_the_emotion_you_are_feeling_right_now'));
      return;
    }

    await storeAnswer('11', t('screens:11.uuid'), checkedValues);
    navigate('/session/13');
  }

  const actions = (<>
    <Button label={t('continue')} variant="primary" onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="11"
      title={`${t('screens:11.step')} ${t('screens:11.title')}`}
      chapter={2}
      progress={77}
      audio={t('screens:11.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:11.text', { nc: t(nc) })} />

      <div className="flex flex-col gap-4 mt-10">
        <div className="flex gap-4">
          <CheckField id="angry" label={t('angry')} optionOn="yes" optionOff="no" value={checkedValues.angry} onChange={(value) => handleCheck('angry', value)} />
          <label htmlFor="angry">{t('angry')}</label>
        </div>
        <div className="flex gap-4">
          <CheckField id="scared" label={t('scared')} optionOn="yes" optionOff="no" value={checkedValues.scared} onChange={(value) => handleCheck('scared', value)} />
          <label htmlFor="scared">{t('scared')}</label>
        </div>
        <div className="flex gap-4">
          <CheckField id="sad" label={t('sad')} optionOn="yes" optionOff="no" value={checkedValues.sad} onChange={(value) => handleCheck('sad', value)} />
          <label htmlFor="sad">{t('sad')}</label>
        </div>
      </div>

      { errorMessage &&
        <Dialog actions={<Button onClick={() => setErrorMessage(null)} label={t('ok')} />}>
          {errorMessage}
        </Dialog>
      }
    </SessionScreen>
  );
};
