import { storeAnswer } from '../../../services/manager';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';

export type StoredValue = string;

export default function Screen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val] = useState('');

  async function answer(variable: String) {
    await storeAnswer('8', t('screens:8.uuid'), val);
    if (variable === 'Yes') navigate('/session/9', { replace: true });
    if (variable === 'No') {
      await storeAnswer('9', t('screens:9.uuid'), "powerless" as StoredValue);
      navigate('/session/10', { replace: true });
    }
  }

  const actions = (<>
    <Button label={t('yes')} variant="primary" onClick={() => answer('Yes')} />
    <Button label={t('no')} variant="primary" onClick={() => answer('No')} />
  </>);

  return (
    <SessionScreen
      screen="8"
      title={`${t('screens:8.step')} ${t('screens:8.title')}`}
      chapter={2}
      progress={44}
      audio={t('screens:8.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:8.text')} />
    </SessionScreen>
  );
};
