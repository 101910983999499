import { ReactComponent as SoundIcon } from '../assets/icons/sound.svg';
import { ReactComponent as SpinnerIcon } from '../assets/icons/spinner.svg';
import { ReactComponent as StopIcon } from '../assets/icons/Icon_awesome-stop-circle.svg';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';

type Props = {
  src: string;
}

enum AudioState {
  Loading = 'loading',
  Ready = 'ready',
  Stopped = 'stopped',
  Playing = 'playing',
  Error = 'error',
}

export default function AudioButton({ src }: Props) {
  const { t } = useTranslation();

  const audioRef = useRef<HTMLAudioElement>(null);
  const [state, setState] = useState<AudioState>(AudioState.Loading);

  const handleClick = () => {
    if (audioRef.current) {
      if (sessionStorage.getItem('autoPlay') === 'true') {
        sessionStorage.setItem('autoPlay', 'false');
      }
      audioRef.current.pause();
      audioRef.current.currentTime = 0;

      if (state !== AudioState.Playing) {
        sessionStorage.setItem('autoPlay', 'true');
        audioRef.current.play();
      }
    }
  };

  const icon = (
    state === AudioState.Loading ?
      <SpinnerIcon className="animate-spin" /> :
      state === AudioState.Playing ?
        <StopIcon className="animate-stop" /> :
      <SoundIcon />
  );

  useEffect(() => {
    if(audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = src;
      audioRef.current.currentTime = 0;
      if (sessionStorage.getItem('autoPlay') === 'true') {
        audioRef.current.play();      }
    }
  }, [src])

  return (
    <>
      <Button
        onClick={handleClick}
        disabled={state === AudioState.Error}
        label={t('read_aloud')}
        variant={state === AudioState.Playing ? 'quinary' : 'tertiary'}
        size="small"
        icon={icon}
        normalcase
      />

      <audio
        preload="metadata"
        ref={audioRef}
        onLoadedMetadata={() => setState(AudioState.Ready)}
        onLoadedData={() => setState(AudioState.Ready)}
        onPlaying={() => setState(AudioState.Playing)}
        onPause={() => setState(AudioState.Stopped)}
        onError={() => setState(AudioState.Error)}
      >
        <source src={src} type="audio/mpeg" />
      </audio>
    </>
  );
}
