import { storeAnswer } from '../../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';

interface OwnProps {
}

export const Screen:React.FC<OwnProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  

  async function answer() {
    await storeAnswer('101', t('screens:101.uuid'));
    navigate('/session/102');
  }

  async function goBack() {
    navigate(-1);
  }

  const actions = (<>
    <Button label={t('go_back')} variant="secondary" onClick={() => goBack()} />
    <Button label={t('continue')} variant="primary" onClick={() => answer()} />
  </>);

  return (
    <>
    <SessionScreen
      screen="101"
      title={`${t('screens:101.step')} ${t('screens:101.title')}`}
      chapter={1}
      progress={17}
      actions={actions}
    >
      <Markdown value={t('screens:101.text')} />
    </SessionScreen>
    </>
  );
};

export default Screen;
