import { storeAnswer } from '../../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';
import VideoPlayer from '../../../components/VideoPlayer'

export default function Screen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function answer() {
    await storeAnswer('103', t('screens:103.uuid'));
    navigate('/session/2');
  }

  async function goBack() {
    navigate(-1);
  }

  const actions = (<>
    <Button label={t('go_back')} variant="secondary" onClick={() => goBack()} />
    <Button label={t('continue')} variant="primary" onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="103"
      title={`${t('screens:103.step')} ${t('screens:103.title')}`}
      chapter={1}
      progress={50}
      audio={t('screens:103.audio')}
      actions={actions}
    >
      <Markdown value={t('screens:103.text')} />
      <VideoPlayer src={t('screens:103.source')} poster={t('screens:103.poster')} />
    </SessionScreen>
  );
};
