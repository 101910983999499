import { clearSession } from '../services/manager';
import { Outlet, useOutletContext, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../components/Button';
import Dialog from '../components/Dialog';
import SessionProgress from '../components/SessionProgress';

type ContextType = {
  setTitle: (value: string) => void;
  setChapter: (value: number) => void;
  setProgress: (value: number) => void;
};

export function useLayout() {
  return useOutletContext<ContextType>();
}

export default function SessionLayout() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [title, setTitle] = useState<string>('');
  const [chapter, setChapter] = useState<number>(1);
  const [progress, setProgress] = useState<number>(0);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  async function handleStopSession() {
    await clearSession();
    navigate('/', { replace: true });
  }

  const dialogActions = (<>
    <Button label={t('stop_session')} variant="secondary" onClick={handleStopSession} />
    <Button label={t('stay_in_session')} onClick={() => setShowDialog(false)} />
  </>);

  return (
    <>
      <header className="sticky z-10 top-0 inset-x-0 bg-lightgray p-6">
        <div className="mb-6">
          <Button label={t('stop_session')} variant="secondary" size="small" normalcase onClick={() => setShowDialog(true)} />
        </div>

        <h1 className="text-xl font-bold">{title}</h1>

        <SessionProgress key="progress" chapter={chapter} progress={progress} />
      </header>

      <Outlet context={{ setTitle, setChapter, setProgress }} />

      { showDialog &&
        <Dialog actions={dialogActions}>
          {t('if_you_stop_the_session_it_will_be_aborted_you_cannot_resume_it')}
        </Dialog>
      }
    </>
  );
};
