import { ReactNode, useEffect } from 'react';
import { useLayout } from '../../layouts/SessionLayout';
import useStoreScreenView from '../../hooks/useStoreScreenView';

type Props = {
  title: string;
  screen: string;
  chapter: number;
  progress: number;
  link?: string;
  voice?: string;
  audio?: string;
  children?: ReactNode;
  actions?: ReactNode;
};

export default function SessionScreen({ title, chapter, progress, link, voice, audio, children, actions, screen }: Props) {
  const { setTitle, setChapter, setProgress } = useLayout();
  link = "https://storage.googleapis.com/moovd-apps/moovd-emdr-flash/README_INSTRUCTIONS/";
  link += localStorage.getItem("i18nextLng")?.includes("nl") ? "NL/" : "ENG/";
  voice = "j.vanzon/";

  useStoreScreenView(screen);

  useEffect(() => {
    setTitle(title);
    setChapter(chapter);
    setProgress(progress);
  });

  return (
    <>
      <div className="flex-grow">
        {children}
      </div>

      { actions &&
        <div className="flex-none flex gap-2 justify-end mt-4">
          {actions}
        </div>
      }
    </>
  );
};
