import { storeAnswer } from '../../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Markdown from '../../../components/Markdown';
import SessionScreen from '.././SessionScreen';
import TimeProgressbar from '../../../components/TimeProgressbar';

export default function Screen() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function onEndTime() {
    await storeAnswer('41', t('screens:41.uuid'));
    navigate('/session/42', { replace: true });
  }

  return (
    <SessionScreen
      screen="41"
      title={`${t('screens:41.step')} ${t('screens:41.title')}`}
      chapter={6}
      progress={25}
      audio={t('screens:41.audio')}
    >
      <Markdown value={t('screens:41.text')} />
      <TimeProgressbar delay={0} time={13} onEndTime={onEndTime} />
    </SessionScreen>
  );
};
